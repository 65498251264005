button:active, button:focus,
input:active, input:focus,
select:active, select:focus {
  box-shadow: none !important;
  border-color: $color-secondary;
  background: #f1f1f1;
  outline: none;
}

button {
  cursor: pointer;
}


.btn-primary {
  @include button-primary(#fff, $color-primary);
  transition: all .2s ease;
  &:hover {
    background-color: darken($color-primary, 10);
  }
  &:after {
    content: "";
    background: get-icon('circle-chevron-right', #fff) no-repeat;
    background-size: 100%;
    height: 25px;
    width: 25px;
    margin-left: 12px;
    display: inline-block;
  }
}

.btn-back {
  background: $color-primary;
  padding: 10px 15px;
  display: inline-flex;
  color: #fff;
  margin-top: 20px;
  &:before {
    content: "";
    background: get-icon('arrow-left', #fff) no-repeat;
    background-size: 100%;
    margin-right: 8px;
    height: 20px;
    width: 20px;
    display: inline-block;
  }
}

.btn-primary--invert {
  @include button-primary(#fff, none);
  &:after {
    content: "";
    background: get-icon('button-arrow-right', #fff) no-repeat;
    background-size: 100%;
    transform: translateX(10px);
    height: 50px;
    width: 50px;
    display: inline-block;
    transition: transform 0.6s;
  }
  &:hover:after {
    transform: translateX(30px);
    background: get-icon('button-arrow-right', $color-primary) no-repeat;
    background-size: 100%;
  }
  &:hover {
    color: $color-primary;
  }
}

.btn-submit {
  padding: 10px;
  font-size: 0.9rem;
  background: $color-primary;
  color: #fff;
  border: none;
  border-radius: 0;
  &:hover,
  &:active {
    background: darken($color-primary, 7)
  }
}

.btn-secondary {
  background: $color-primary;
  padding: 10px 15px;
  display: inline-flex;
  color: #fff;
  margin-top: 20px;
  &:after {
    content: "";
    background: get-icon('arrow-right', #fff) no-repeat;
    background-size: 100%;
    margin-left: 8px;
    height: 20px;
    width: 20px;
    display: inline-block;
  }
}

.btn-secondary.active {
  color: $color-secondary !important;
  background-color: #efefef !important
}



.btn--inverted {
  color: #fff;
  border-radius: 0;
  border: solid 2px #fff;
  background: none;
  &:hover {
    background: #fff;
    color: #343a40
  }
}

.btn--inverted.active {
  box-shadow: none;
  background: $color-secondary;
  color: #fff;
  &:focus {
    border: solid 1px #343a40 !important;
  }
}

.btn-special {
  text-align: center;
  background: url('/files/images/icons/ellipse_dashed.png');
  background-size: contain;
  display: flex;
  align-items: center;
  width: 150px;
  height: 150px;
  color: #fff;
  margin: auto;
}