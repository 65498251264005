.swiper-controll-wrapper {
  height: 150px;
  width: 100%;
  position: relative;
}

.swiper-container {
  .swiper-pagination {
    @include responsive(phone) {
      display: block;
    }
    display: none;
    font-size: 2.5rem;
    width: fit-content;
    right: 0;
    left: 0;
    margin: auto;
    color: #000000;
    position: absolute;
    opacity: 0.7;
  }
}
.swiper-button-prev, .swiper-button-next, .swiper-pagination {
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0px;
}

.swiper-button-next, .swiper-button-prev {
  @include responsive(phone) {
    width: 65px;
    height: 65px;
  }
  width: 25px;
  height: 25px;
}

.swiper-button-next:after, .swiper-button-prev:after {
  content: none;
}

.swiper-button-next {
  background: get-icon('chevron-right', #939393) no-repeat;
  background-size: 50%;
  background-position: center;
  border-radius: 50%;
  border: solid 2px #939393;
}

.swiper-button-prev {
  background: get-icon('chevron-left', #939393) no-repeat;
  background-size: 50%;
  background-position: center;
  border-radius: 50%;
  border: solid 2px #939393;
}

.customers-swiper {
  overflow: visible;
  position: relative;
  .swiper-wrapper {
    align-items: center;
    max-width: 800px;
  }
  .swiper-button-next {
    height: 35px;
    width: 35px;
  }
  .swiper-button-next {
    @include responsive(landscape) {
      display: block;
      background: get-icon('arrow-right', grey) no-repeat;
      width: 35px;
      height: 35px;
      background-size: 100%;
      right: -10%;
      position: absolute;
    }
    display: none;
  }
}



.swiper-pagination {
  left: 0;
  right: 0;
  margin: auto;
  color :$color-primary;
}

.testimonials-swiper {
  h3 {
    padding: 20px 20px 0px 20px;
  }
  p {
    padding: 0 20px 20px 20px;
  }
}

.header__start {
  .swiper-button-prev {
    background: get-icon('chevron-left', #fff) no-repeat;
    background-size: 50%;
    background-position: center;
    border-radius: 0px;
    border: none;
  }
  .swiper-button-next {
    background: get-icon('chevron-right', #fff) no-repeat;
    background-size: 50%;
    background-position: center;
    border-radius: 0px;
    border: none;
  }
}