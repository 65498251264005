/*========================Footer======================*/
#footer {
	color: #fff;
	font-size: 0.8rem;
	background-color: $color-primary;
	.footer__wrapper {
		padding: 50px 0px;
	}
	.footer__links {
		h4 {font-size: 1.2rem; color: $color-tertiary;}
		ul {padding: 0}
	}
	.footer__social {
		display: flex;
		svg {
			height: 25px;
			width: 25px;
			margin: 10px 15px 0px 0px;
		}
	}
	h3 {
		color: #fff;
	}
	h4, h4 a {
		color: #000;
		font-weight: 700;
		text-transform: uppercase
	}
	a {
		color: $color-tertiary;
	}
}

.copyright-wrapper {
	padding-top: 20px;
	.copyright {
		font-size: 0.7rem;
	}
	.copyright__navigation {
		display: flex;
		justify-content: flex-end;
		ul {
			display: flex;
			list-style: none;
			li:first-child {
				margin-right: 10px;
			}
		}
	}
	a {color: #fff}
}
