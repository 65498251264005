/* jost-regular - latin */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/jost-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/jost-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/jost-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/jost-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/jost-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/jost-v14-latin-regular.svg#Jost') format('svg'); /* Legacy iOS */
}
/* jost-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Jost';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/jost-v15-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/jost-v14-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/jost-v14-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/jost-v14-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/jost-v14-latin-900.woff') format('woff'), /* Modern Browsers */
  url('../fonts/jost-v14-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/jost-v14-latin-900.svg#Jost') format('svg'); /* Legacy iOS */
}

/* herr-von-muellerhoff-regular - latin */
@font-face {
  font-family: 'Herr Von Muellerhoff';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/herr-von-muellerhoff-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/herr-von-muellerhoff-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/herr-von-muellerhoff-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/herr-von-muellerhoff-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/herr-von-muellerhoff-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/herr-von-muellerhoff-v15-latin-regular.svg#HerrVonMuellerhoff') format('svg'); /* Legacy iOS */
}

/* parisienne-regular - latin */
@font-face {
  font-family: 'Parisienne';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/parisienne-v13-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/parisienne-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/parisienne-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/parisienne-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/parisienne-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/parisienne-v13-latin-regular.svg#Parisienne') format('svg'); /* Legacy iOS */
}